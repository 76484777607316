<template>
  <div class="bookings-index">
    <div class="datepickers">
      <UIDatepicker :options="datePickerOptions" :title="$t('Start date')" :value="startDate" @update="setStartDate($event)" />
      <UIDatepicker :options="datePickerOptions" :title="$t('End date')" :value="endDate" @update="setEndDate($event)" />
      <UIButton class="export" @click="loadBookings">{{ $t('Update') }}</UIButton>
    </div>
    <div class="container">
      <div v-for="startDateYMD in bookingStartDatesYMD" :key="startDateYMD">
        <h2 class="f3">{{ formattedDate(startDateYMD) }}</h2>
        <table class="bookings-table">
          <thead>
            <tr>
              <th>{{ $t('Date / time') }}</th>
              <th>{{ $t('Duration') }}</th>
              <th>{{ $t('Name') }}</th>
              <th>{{ $t('Players') }}</th>
              <th>{{ $t('Stations') }}</th>
              <th>{{ $t('Email') }}</th>
              <th>{{ $t('Phone') }}</th>
              <th>{{ $t('Language') }}</th>
              <th>{{ $t('Paid') }}</th>
              <th v-if="waiversEnabled">{{ $t('Waivers') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="booking in bookingsByStartDateYMD(startDateYMD)" :key="booking.id">
              <td>{{ formattedBookingStartTime(booking) }}</td>
              <td>{{ durationMin(booking) }}</td>
              <td v-if="booking.host">{{ booking.host.firstName }} {{ booking.host.lastName }}</td>
              <td v-else></td>
              <td>{{ booking.numPlayers }}</td>
              <td>{{ booking.numStations }}</td>
              <td v-if="booking.host"><a class="link-style" :href="'mailto:' + booking.host.email">{{ booking.host.email }}</a>  </td>
              <td v-else></td>
              <td v-if="booking.host">{{ booking.host.phone }}</td>
              <td v-else></td>
              <td>{{ hostLanguage(booking) }}</td>
              <td class="icon"><svg><use v-bind:xlink:href="paidIcon(booking)"></use></svg></td>
              <td v-if="waiversEnabled"><a class="link-style" :href="manageWaivers(booking)" target="_blank">{{ waiverCount(booking) }}</a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Panel from '@/core/shared/components/Panel';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIGridIndexLoader from '@/core/shared/components/ui/UIGridIndexLoader';
import UIDatepicker from '@/core/shared/components/ui/UIDatepicker';
import moment from 'moment-timezone';

@Component({
  props: {
    locationId: {
      type: String,
      required: true,
    },
  },
  components: {
    UIButton,
    UIGridIndexLoader,
    Panel,
    UIDatepicker,
  },
  data () {
    return {
      startDate: moment().format(),
      endDate: moment().add(1, 'week').format(),
    };
  },
})
export default class LocationBookingIndexRoute extends Vue {
  loading = true;
  location = {
    waiversRequired: false,
  };

  bookings = [];

  datePickerOptions = { altFormat: 'F d, Y' };

  get isLoading () {
    return this.loading && this.bookings.length === 0;
  }

  get waiversEnabled () {
    return this.location.waiversRequired;
  }

  waiverCount (booking) {
    return `${booking.waiverNumSigned + booking.waiverNumMinors}/${booking.numPlayers}`;
  }

  manageWaivers (booking) {
    const url = booking.location.waiverHostProxyUrl ?? 'https://' + window.sbvrenv.CUSTOMER_URL + '/manage-waivers/operator';
    return `${url}/${booking.waiverReferenceHost}`;
  }

  get bookingStartDatesYMD () {
    return [...new Set(this.bookings.map(x => this.localStartTime(x).format('YYYY-MM-DD')))];
  }

  paidIcon (booking) {
    if (this.isPaid(booking)) {
      return '#icon-check';
    }
    return '#icon-x';
  }

  isPaid (booking) {
    return booking.paymentStatus === 'completed';
  }

  formattedDate (startDateYMD) {
    return moment(startDateYMD + ' 00:00:00').format('MMMM Do YYYY');
  }

  durationMin (booking) {
    const startTime = Math.min.apply(Math, booking.bookingStationTimes.map(function (x) { return new Date(x.startTime).getTime(); }));
    const endTime = Math.max.apply(Math, booking.bookingStationTimes.map(function (x) { return new Date(x.endTime).getTime(); }));
    const diffMs = new Date(endTime) - new Date(startTime);
    const minutes = Math.round(diffMs / 60000);
    return isNaN(minutes) ? '?' : `${minutes}m`;
  }

  bookingsByStartDateYMD (startDateMD) {
    return this.bookings.filter(x => this.localStartTime(x).format('YYYY-MM-DD') === startDateMD);
  }

  localStartTime (booking) {
    return moment.utc(booking.startTime).tz(booking.location.timezone);
  }

  formattedBookingStartTime (booking) {
    return this.localStartTime(booking).format('DD-MM HH:mm');
  }

  hostLanguage (booking) {
    if (booking.host && booking.host.locale) {
      return booking.host.locale.substring(0, 2);
    }
    return '?';
  }

  setStartDate (date) {
    this.startDate = moment(date).format();
    this.swapDatesIfNecessary();
  }

  setEndDate (date) {
    this.endDate = moment(date).format();
    this.swapDatesIfNecessary();
  }

  swapDatesIfNecessary () {
    if (this.startDate > this.endDate) {
      const tmp = this.endDate;
      this.endDate = this.startDate;
      this.startDate = tmp;
    }
  }

  updateDateRange (which, dateRange) {
    if (which === 'startDate') {

    }
    Vue.set(dateRange.value, dateRange);
  }

  async loadBookings () {
    this.bookings = await this.$store.dispatch('getUpcomingBookings', {
      locationId: this.locationId,
      startDate: this.startDate,
      endDate: this.endDate,
    });
  }

  async created () {
    this.loadBookings();
    this.location = this.$store.getters['entities/Location'](this.locationId);
    this.loading = false;
    this.$store.commit('setTitle', this.$t('Upcoming Bookings'));
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.bookings-index {
  padding-bottom: 7rem;

  & .datepickers {
    @apply --container;
    padding-top: var(--spacingSm);
    padding-bottom: var(--spacingSm);
    width: 100%;
    background-color: rgba(255, 255, 255, .8);
    display: grid;
    grid-template-columns: 20rem 20rem 15rem 80rem;
    grid-gap: var(--spacingSm);

    & button {
      margin-top: 2rem;
      height: 4.8rem;
    }
  }

  & .container {
    @apply --container;
  }

  & h2 {
    margin-top: var(--spacingSm);
  }

  & .bookings-table {
    @apply --f6;
    border-collapse: collapse;
    border-radius: var(--radius);
    overflow: hidden;
    background-color: var(--colorWhite);
    box-shadow: var(--shadowSm);
    margin: var(--spacingSm) 0;
    width: 100%;

    & thead tr {
      background-color: var(--colorStorm);
      color: #ffffff;
      text-align: left;
      border-radius: var(--radius);
    }

    & th,
    & td {
      padding: var(--spacingSm);
    }
  }

  & svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  & .icon {
    justify-content: center;
    align-items: center;
    padding: 0;
  }
}

</style>
